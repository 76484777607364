import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/elements/404.vue')
    },
    {
        path: '/',
        name: 'HomePathSupport',
        component: () => import(/* webpackChunkName: "HomeBranding" */ '../views/HomePathSupport.vue'),
    },
    
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/pages/PrivacyPolicy.vue'),
    },
];
  
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
  
export default router;
